import { useIntercom, IntercomProps } from 'react-use-intercom'
import { useEffect, createContext, useCallback, useRef, useMemo } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client' // user apollo useMutation, rather than our custom one, since we don't care about showing a Toast on error in this case

import { useUserSession } from 'hooks'

import { AccountableTypeEnum } from '../../../__generated__/globalTypes'
import GET_DATA_FOR_INTERCOM from './graphql/GetDataForIntercom.graphql'
import {
  GetDataForIntercom,
  GetDataForIntercom_currentAccount_accountable_Rep,
  GetDataForIntercom_currentAccount_accountable_Seller,
  GetDataForIntercom_currentAccount_accountable_Buyer,
  GetDataForIntercom_currentAccount_accountable_Seller_accountAddress,
  GetDataForIntercom_currentAccount_accountable_Seller_billingAddress,
  GetDataForIntercom_currentAccount_accountable_Buyer_billingAddress,
  GetDataForIntercom_currentAccount_accountable_Buyer_shippingAddress,
  GetDataForIntercom_currentAccount_accountable_Rep_location
} from './graphql/__generated__/GetDataForIntercom'
import APPLY_INTERCOM_TAGS_MUTATION from './graphql/ApplyIntercomTagsMutation.graphql'
import { ApplyIntercomTagsMutation } from './graphql/__generated__/ApplyIntercomTagsMutation'

type Location = GetDataForIntercom_currentAccount_accountable_Rep_location

type FullAddress =
  | GetDataForIntercom_currentAccount_accountable_Seller_accountAddress
  | GetDataForIntercom_currentAccount_accountable_Seller_billingAddress
  | GetDataForIntercom_currentAccount_accountable_Buyer_billingAddress
  | GetDataForIntercom_currentAccount_accountable_Buyer_shippingAddress

const isProd = Boolean(process.env.NEXT_PUBLIC_ENV === 'production')
const env = (process.env.NEXT_PUBLIC_ENV || '').toString().toUpperCase()
export const isSSR = typeof window === 'undefined'

export const IntercomContext = createContext<{
  bootIntercom: () => void
  hideIntercom: (shouldHide: boolean) => void
}>({ bootIntercom: () => null, hideIntercom: () => null })

export const IntercomContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { isGuest } = useUserSession()
  const { boot, update, hide, shutdown } = useIntercom()
  const [getDataForIntercom] = useLazyQuery<GetDataForIntercom>(GET_DATA_FOR_INTERCOM)
  const [applyIntercomTags] = useMutation<ApplyIntercomTagsMutation>(APPLY_INTERCOM_TAGS_MUTATION)

  const defaultIntercomSettings = useMemo(
    () => ({
      verticalPadding: 100
    }),
    []
  )

  const isHidden = useRef(false)
  const isBooted = useRef(false)
  const intercomProps = useRef<IntercomProps>({ ...defaultIntercomSettings })

  const bootIntercom = useCallback(async () => {
    if (!isGuest) {
      const result = await getDataForIntercom()
      intercomProps.current = { ...defaultIntercomSettings }
      if (!result.error) {
        const user = result.data?.currentUser
        const account = result.data?.currentAccount

        let seller, buyer, rep

        if (account?.type === AccountableTypeEnum.SELLER) {
          seller = account.accountable as GetDataForIntercom_currentAccount_accountable_Seller
        }

        if (account?.type === AccountableTypeEnum.BUYER) {
          buyer = account.accountable as GetDataForIntercom_currentAccount_accountable_Buyer
        }

        if (account?.type === AccountableTypeEnum.REP) {
          rep = account.accountable as GetDataForIntercom_currentAccount_accountable_Rep
        }

        if (user) {
          intercomProps.current.userId = isProd ? user.id : `${env}_${user.id}`
          intercomProps.current.userHash = user.intercomUserHash
          intercomProps.current.email = user.email
          intercomProps.current.name = isProd
            ? `${user.firstName} ${user.lastName}`
            : `(${env}) ${user.firstName} ${user.lastName}`
          intercomProps.current.createdAt = user.createdAt
          intercomProps.current.customAttributes = {
            active_admin_url: `${process.env.NEXT_PUBLIC_APP_URL}/admin/users/${user.id}`
          }
        }

        const address = (fullAddress: FullAddress | null, location: Location | null = null) => {
          const a = [
            fullAddress?.street1,
            fullAddress?.street2,
            fullAddress?.city,
            fullAddress?.state,
            fullAddress?.country
          ]
          const b = [location?.city, location?.state, location?.country]
          return a
            .concat(b)
            .filter(i => i)
            .join(', ')
        }

        if (account && seller) {
          intercomProps.current.company = {
            companyId: isProd ? account.id : `${env}_${account.id}`,
            name: isProd ? seller.displayName : `(${env}) ${seller.displayName}`,
            createdAt: seller.createdAt,
            plan: seller.commonSubscriptionDetails?.plan.id,
            // NOTE: custom attributes must be snake_case for Intercom to accept them
            customAttributes: {
              phone: seller.accountAddress?.phone || seller.billingAddress?.phone,
              email: seller.roles.nodes?.[0]?.user?.email,
              country: seller.country,
              account_address: address(seller.accountAddress),
              billing_address: address(seller.billingAddress),
              catalogs: seller.catalogs.nodes.map(c => c.name).join(', '),
              marketplace_url: `${document.location.origin}/${seller.slug}`,
              active_admin_url: `${process.env.NEXT_PUBLIC_APP_URL}/admin/sellers/${seller.id}`
            }
          }
        }

        if (account && buyer) {
          intercomProps.current.company = {
            companyId: isProd ? account.id : `${env}_${account.id}`,
            name: isProd ? buyer.displayName : `(${env}) ${buyer.displayName}`,
            createdAt: buyer.createdAt,
            // NOTE: custom attributes must be snake_case for Intercom to accept them
            customAttributes: {
              phone: buyer.phone || buyer.billingAddress?.phone || buyer.shippingAddress?.phone,
              email: buyer.email || buyer.roles.nodes?.[0]?.user?.email,
              country: buyer.billingAddress?.country,
              billing_address: address(buyer.billingAddress),
              shipping_address: address(buyer.shippingAddress),
              active_admin_url: `${process.env.NEXT_PUBLIC_APP_URL}/admin/buyers/${buyer.id}`
            }
          }
        }

        if (account && rep) {
          intercomProps.current.phone = rep?.phone || ''
          if (!intercomProps.current.customAttributes) {
            intercomProps.current.customAttributes = {}
          }
          // NOTE: custom attributes must be snake_case for Intercom to accept them
          intercomProps.current.customAttributes.address = address(null, rep?.location)
        }
      }
    }

    if (!isHidden.current) {
      console.log('booting intercom', intercomProps.current)
      boot(intercomProps.current)
      applyIntercomTags()
    }

    // if Intercom should currently be hidden, then delay booting until we
    // visit a page which has intercom visible
    isBooted.current = !isHidden.current
  }, [isGuest, boot, getDataForIntercom, applyIntercomTags, defaultIntercomSettings])

  const hideIntercom = useCallback(
    (shouldHide: boolean) => {
      if (isSSR) return

      if (!shouldHide && !isBooted.current) {
        console.log('booting intercom2', intercomProps.current)
        boot(intercomProps.current)
        applyIntercomTags()
        isBooted.current = true
      }

      if (isBooted.current) {
        if (shouldHide) {
          // All 'hide' does is hide the 'messenger' - i.e. where you type messages
          hide()

          // This is the only way to hide the Intercom "launch icon"
          update({ hideDefaultLauncher: true })

          console.log('shutting down intercom')

          // NOTE: calling shutdown here, as this is the *only way* I can find
          // to hide the Intercom "Chat Welcome Message". Regular 'hide'
          shutdown()
          isBooted.current = false
        }
      }

      isHidden.current = shouldHide
    },
    [boot, applyIntercomTags, hide, update, shutdown]
  )

  useEffect(() => {
    bootIntercom()
  }, [bootIntercom])

  return <IntercomContext.Provider value={{ hideIntercom, bootIntercom }}>{children}</IntercomContext.Provider>
}
