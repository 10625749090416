import { createContext, ReactNode, useEffect, useState } from 'react'
import { ApolloQueryResult, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'

import { useUserSession } from 'hooks'

import ModifySubscriptionModal from './ModifySubscriptionModal'

import GET_SUBSCRIPTION_ADDONS_USAGE from './graphql/GetSubscriptionAddonsUsage.graphql'
import { GetSubscriptionAddonsUsage } from './graphql/__generated__/GetSubscriptionAddonsUsage'
import { PlanEnum } from '../../../__generated__/globalTypes'

export type AddonType = 'catalog' | 'staff' | 'rep'

export type ModalContentProps = {
  title?: string | ReactNode
  description?: string | ReactNode
  buttonLabel?: string
}

type ModifySubscriptionContextType = {
  modalProps: {
    isOpen: boolean
    type: AddonType | null
    email?: string
    quantity?: number
  }
  isRecurringPlan: boolean
  hasReachedLimit: Record<AddonType, boolean>
  refetchSubscriptionAddonsUsage: () => Promise<ApolloQueryResult<GetSubscriptionAddonsUsage>>
  ensureAddonCapacity: ({
    addonType,
    email,
    customContent
  }: {
    addonType: AddonType
    email?: string
    customContent?: ModalContentProps
  }) => Promise<boolean>
  toggleCanShowModal: () => void
  customModalContent?: ModalContentProps
}

const ModifySubscriptionContext = createContext<ModifySubscriptionContextType>({
  modalProps: { isOpen: false, type: null, email: undefined, quantity: 1 },
  isRecurringPlan: false,
  hasReachedLimit: { catalog: true, staff: true, rep: true },
  refetchSubscriptionAddonsUsage: () => Promise.resolve({} as ApolloQueryResult<GetSubscriptionAddonsUsage>),
  ensureAddonCapacity: () => Promise.resolve(false),
  toggleCanShowModal: () => undefined,
  customModalContent: undefined
})

export default ModifySubscriptionContext

export const ModifySubscriptionContextProvider = ({ children }: { children: ReactNode }) => {
  const { isSeller } = useUserSession()
  const router = useRouter()
  const [modalProps, setModalProps] = useState<{
    isOpen: boolean
    type: AddonType | null
    email?: string
    quantity?: number
  }>({
    isOpen: false,
    type: null,
    email: undefined,
    quantity: 1
  })
  const [canShowModal, setCanShowModal] = useState(true)
  const [modalPromise, setModalPromise] = useState<(value: boolean) => void | undefined>()
  const [customModalContent, setCustomModalContent] = useState<ModalContentProps | undefined>(undefined)

  const onExit = () => {
    setModalProps({ isOpen: false, type: null, email: undefined, quantity: 1 })
    setCustomModalContent(undefined)

    setCanShowModal(true)
  }

  const { loading, data, refetch } = useQuery<GetSubscriptionAddonsUsage>(GET_SUBSCRIPTION_ADDONS_USAGE, {
    skip: !isSeller
  })

  const subscription = data?.currentSeller?.recurringSubscriptionDetails
  const isRecurringPlan = data?.currentSeller.plan.id === PlanEnum.RECURRING

  const hasReachedLimit = {
    catalog: subscription?.hasReachedCatalogsLimit ?? true,
    staff: subscription?.hasReachedStaffLimit ?? true,
    rep: subscription?.hasReachedRepsLimit ?? true
  }

  // Function returns a promise allowing the calling function to only proceed if it resolves true
  // - If there is sufficient capacity, it immediately resolves true
  // - If user does not have sufficient capacity, it will then open a modal asking user to upgrade their addons
  // - If user cancels the modal or fails to update their capacity it resolves false
  // - If upgrade is successful it resolves true.
  const ensureAddonCapacity = async ({
    addonType,
    email,
    customContent
  }: {
    addonType: AddonType
    email?: string
    customContent?: ModalContentProps
  }): Promise<boolean> => {
    if (isRecurringPlan && hasReachedLimit[addonType]) {
      // NOTE: If `canShowModal` is false, the subscription modal has already been displayed,
      // and the seller has confirmed the upgrade.
      // Since it does not need to be shown again, proceed with the action.
      if (!canShowModal) return true

      // Trigger modal for required addon upgrade
      return new Promise(resolve => {
        setModalProps({ isOpen: true, type: addonType, email, quantity: 1 })
        setCustomModalContent(customContent)
        setModalPromise(() => resolve)
      })
    }

    // No need to upgrade, proceed with the action
    return true
  }

  const onConfirmAddonSubscription = (isConfirmed: boolean) => {
    if (modalPromise) {
      modalPromise(isConfirmed)
      setModalPromise(undefined)
    }

    onExit()
  }

  // Ensures the modal state is reset when leaving the new catalog page,
  // acting as a safeguard to display the modal if it was unintentionally left hidden while navigating away from `/sh/catalogs/new`.
  useEffect(() => {
    if (router.pathname !== '/sh/catalogs/new') {
      setCanShowModal(true)
    }
  }, [router.pathname])

  const toggleCanShowModal = () => setCanShowModal(!canShowModal)

  if (loading) return null

  return (
    <ModifySubscriptionContext.Provider
      value={{
        modalProps,
        isRecurringPlan,
        hasReachedLimit,
        refetchSubscriptionAddonsUsage: refetch,
        ensureAddonCapacity,
        customModalContent,
        toggleCanShowModal
      }}>
      {modalProps.type && isRecurringPlan && (
        <ModifySubscriptionModal
          isOpen={modalProps.isOpen && canShowModal}
          addonType={modalProps.type}
          email={modalProps.email}
          quantity={modalProps.quantity}
          data={data}
          loading={loading}
          onExit={onExit}
          onConfirm={onConfirmAddonSubscription}
          content={customModalContent}
        />
      )}

      {children}
    </ModifySubscriptionContext.Provider>
  )
}
