import ReactModal, { Props } from 'react-modal'
import classnames from 'classnames'

import styles from './ModalContainer.module.css'
import { useEffect } from 'react'

// This will work with both Next.js and Storybook
ReactModal.setAppElement('#__next,#root')

// Reset default styles, so we can use our own
ReactModal.defaultStyles = {}

export type ModalContainerProps = Props & {
  title?: string
  onExit: () => void
  closeable?: boolean
  isPageScroll?: boolean
}

const ModalContainer = ({
  title,
  children,
  onExit,
  closeable = true,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  overlayClassName,
  bodyOpenClassName,
  htmlOpenClassName,
  className,
  isPageScroll = false,
  isOpen,
  ...rest
}: ModalContainerProps) => {
  // Prevent scrolling on the page when the modal is open, and ensures scrolling is enabled when the modal is closed
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={title}
      onRequestClose={onExit}
      shouldCloseOnEsc={shouldCloseOnEsc ?? closeable}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick ?? closeable}
      overlayClassName={classnames(styles.overlay, overlayClassName)}
      className={classnames(styles.content, { [styles.hideScroll]: isPageScroll }, className)}
      bodyOpenClassName={classnames(styles.bodyOpen, bodyOpenClassName)}
      htmlOpenClassName={classnames(styles.htmlOpen, htmlOpenClassName)}
      {...rest}>
      <>{children && children}</>
    </ReactModal>
  )
}

export default ModalContainer
