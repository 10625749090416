import capitalize from 'lodash.capitalize'
import { useRouter } from 'next/router'

import { formatCurrency } from 'lib/currencies'
import Icon from 'components/Icon'
import Button from 'components/Button'
import ModalContainer, { ModalContainerProps } from 'components/ModalContainer/ModalContainer'
import { AddonType, ModalContentProps } from './ModifySubscriptionContext'
import { GetSubscriptionAddonsUsage } from './graphql/__generated__/GetSubscriptionAddonsUsage'

import styles from './ModifySubscriptionModal.module.css'

type ModifySubscriptionModalProps = {
  addonType: AddonType
  quantity?: number
  data: GetSubscriptionAddonsUsage | undefined
  loading: boolean
  onConfirm: (confirmed: boolean) => void
  email?: string
  content?: ModalContentProps
} & ModalContainerProps

const ModifySubscriptionModal = ({
  addonType,
  quantity = 1,
  data,
  loading,
  isOpen,
  onExit,
  onConfirm,
  email,
  content,
  ...rest
}: ModifySubscriptionModalProps) => {
  const router = useRouter()
  const altPaths = ['/sh/sales-reps/invite', '/sh/catalogs/new']
  const useAlt = altPaths.includes(router.pathname)

  const plan = data?.currentSeller?.plan
  const currency = plan?.currency ?? data?.currentSeller.baseCurrencyCode
  const additionalCatalogsCost = plan?.additionalCatalogsCost ?? 0
  const additionalStaffCost = plan?.additionalStaffCost ?? 0
  const additionalRepsCost = plan?.additionalRepsCost ?? 0
  const price =
    addonType === 'catalog' ? additionalCatalogsCost : addonType === 'staff' ? additionalStaffCost : additionalRepsCost

  const getSubscriptionModalContent = (addonType: AddonType, useAlt = false) => {
    const subscriptionModalContent: Record<
      AddonType,
      { title: string; description: JSX.Element; buttonLabel: string }
    > = {
      // For catalogs, default text shows when 'Add Catalog' button is pressed,
      // and alternative is during direct navigation to 'New Catalog' page
      catalog: {
        title: `${useAlt ? 'Add' : 'Adding'} another catalog to your subscription`,
        description: (
          <>
            Creating another catalog will increase your monthly subscription charges as shown below. To remove the extra
            catalog from your subscription, simply deactivate the catalog.
          </>
        ),
        buttonLabel: 'Add Catalog to Subscription'
      },
      // For reps, default text is when 'approving' a rep request,
      // and the alternative text is when 'inviting' a rep
      rep: {
        title: useAlt
          ? // Inviting a rep
            'Add a Sales Rep seat to your subscription'
          : // Approving a rep request
            'Add a Sales Rep user to approve this Sales Rep request',
        description: (
          <div>
            To {useAlt ? 'invite' : 'approve'} <strong>{email}</strong>, you need to add another Sales Rep seat to your
            subscription. This will increase your monthly subscription charges as shown below.
          </div>
        ),
        buttonLabel: 'Add Sales Rep User and Approve'
      },
      staff: {
        title: 'Add a Staff Seat to your subscription',
        description: (
          <div>
            To invite <strong>{email}</strong>, you need to add another Staff member to your subscription. This will
            increase your monthly subscription charges as shown below.
          </div>
        ),
        buttonLabel: 'Add Staff User and Approve'
      }
    }

    return subscriptionModalContent[addonType]
  }

  const {
    title: defaultTitle,
    description: defaultDescription,
    buttonLabel: defaultButtonLabel
  } = getSubscriptionModalContent(addonType, useAlt)

  const title = content?.title ?? defaultTitle
  const description = content?.description ?? defaultDescription
  const buttonLabel = content?.buttonLabel ?? defaultButtonLabel

  const closeModal = () => onConfirm(false)

  if (loading) return null

  return (
    <>
      <ModalContainer isOpen={isOpen} onExit={onExit} {...rest}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <h3 className={styles.modalTitle}>{title}</h3>
            <div>
              <nav>
                <a className={styles.modalClose} onClick={closeModal}>
                  <Icon kind="x" size={16} />
                </a>
              </nav>
            </div>
          </div>
          <div className={styles.modalBody}>
            {description}
            <div className={styles.addonDetails}>
              <p className={styles.addonTitle}>Subscription Add-On Change</p>
              <div className={styles.addonPricing}>
                <p>
                  +{quantity} Extra {capitalize(addonType)}
                </p>
                <p className={styles.addonPrice}>+{formatCurrency(price, currency)} per month</p>
              </div>
              <p className={styles.grayedText}>Pricing shown in Australian Dollars (AUD) excluding GST.</p>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <Button kind="default" onClick={closeModal}>
              Cancel
            </Button>
            <Button kind="primary" onClick={() => onConfirm(true)}>
              {buttonLabel}
            </Button>
          </div>
        </div>
      </ModalContainer>
    </>
  )
}

export default ModifySubscriptionModal
